<template>
  <div>
    <v-row v-if="flagSelectRVM" class="ml-6 mt-5">
      <v-col cols="12" sm="10" md="6" lg="4">
        <v-select
            :items="rvms"
            label="Choose the VRVM you want to use"
            v-model="currentRVM"
            item-text="title"
            item-value="id"
            dense
            outlined
            @change="selectRVM()"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="flagRVMVerified">

    <breadcrumbs :items="breadcrumbs"/>

    <div class="ml-6">
      <v-row v-if="!scanStarted">
        <v-col cols="12">
          <v-btn color="success" large @click="startScanGUI()">
            <v-icon>mdi-chevron-right</v-icon>
            Start Scan
          </v-btn>
        </v-col>
      </v-row>

      <div v-show="scanStarted">
        <v-row class="mt-1">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
                type="number"
                label="Scan a new item"
                placeholder="Product barcode"
                v-model="newItemBarcode"
                ref="newItemBarcode"
                @keyup.enter="getProductDetails()"
                autofocus
                outlined
                dense
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-btn
                @click="getProductDetails()"
                color="primary"
                :disabled="!newItemBarcode || flagVerifyingBarcode"
            >
              <v-icon size="20" class="mr-1">mdi-magnify</v-icon>
              Search
            </v-btn>
          </v-col>

          <v-col cols="12" lg="3">
            <p class="text-right">
              Scanned items:
              <v-chip x-small>{{ newItems.length + existingItems.length }}</v-chip>
              <br/>
              Existing items:
              <v-chip x-small>{{ existingItems.length }}</v-chip>
              <br/>
              New items:
              <v-chip x-small>{{ newItems.length }}</v-chip>
              <br/>
              Value (ZAR):
              <v-chip x-small>{{ compTotalValue }}</v-chip>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col>
            <v-chip color="info">Existing products</v-chip>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col cols="11">
            <v-data-table
                dense
                :headers="scannedItemsHeaders"
                :items="existingItems"
                item-key="name"
                class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon @click="deleteItem(existingItems, item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col>
            <v-chip color="warning">New products</v-chip>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col cols="11">
            <v-data-table
                dense
                :headers="scannedItemsHeaders"
                :items="newItems"
                item-key="name"
                class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon @click="deleteItem(newItems, item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col cols="11" class="text-center">
            <v-dialog
                v-model="dlgFinishScan"
                persistent
                max-width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    large
                    @click=""
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="newItems.length + existingItems.length < 1"
                >
                  <v-icon size="20" class="mr-1">mdi-database</v-icon>
                  Finish scan
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="subtitle-1">
                  Finish process
                </v-card-title>

                <v-container>
                  <v-row>
                    <v-col cols="10" offset="1">
                      <v-text-field
                          type="number"
                          label="Type the user's mobile number"
                          placeholder="Mobile number"
                          v-model="mobileNumber"
                          append-icon="mdi-phone"
                          @keyup.enter="confirmTransaction()"
                          @keyup.esc="dlgFinishScan = false"
                          autofocus
                          outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="success"
                      :disabled="flagPostingTransaction || mobileNumber.length < 10"
                      @click="confirmTransaction()"
                  >
                    Yes, proceed
                  </v-btn>
                  <v-btn
                      color="warning"
                      @click="dlgFinishScan = false"
                  >
                    Not now
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </div>

    <axios ref="axios" v-on:finish="handleAxiosResponse($event)"/>

  </div>
  </div>
</template>

<script>

export default ({
  name: "BarcodeScanner",

  data: () => ({
    breadcrumbs: [
      {text: "Barcode Scanner", disabled: true,}
    ],
    mobileNumber: "",
    operationResultMessage: "",
    operationResultColor: "",
    snackbar: false,
    dlgFinishScan: false,
    scanStarted: false,
    flagSelectRVM: false,
    flagRVMVerified: false,
    flagVerifyingBarcode: false,
    flagPostingTransaction: false,
    newItemBarcode: "",
    scannedItemsHeaders: [
      {text: "Barcode", value: "product_id"},
      {text: "Name", value: "title"},
      {text: "Brand", value: "brand"},
      {text: "Weight", value: "weight"},
      {text: "Material", value: "material"},
      {text: "Value (ZAR)", value: "value"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    existingItems: [],
    newItems: [],
    endpoints: {
      getProductDetails: 'vrvm/scanner/get-product-details',
      storeTransaction: 'vrvm/scanner/store-transaction',
    },
    rvms: [],
    currentRVM: null,
  }),
  mounted() {
    this.currentRVM = this.$store.getters.getCurrentRVM

    if (!this.currentRVM) {
      this.rvms = this.$store.getters.getRVMs

      if (this.rvms.length === 0) {
        this.$router.push('/no-rvm')
      }
      else if (this.rvms.length === 1) {
        this.currentRVM = this.rvms[0].id
        this.flagRVMVerified = true
      } else {
        this.flagSelectRVM = true
      }
    }
  },
  computed: {
    compTotalValue: function () {
      let totalValue = this.existingItems.reduce(function (cumulative, b) {
        return cumulative + b.value
      }, 0)

      return totalValue.toFixed(2)
    }
  },
  methods: {
    selectRVM() {
      this.flagRVMVerified = true
      this.flagSelectRVM = false
    },
    startScanGUI() {
      this.$refs.newItemBarcode.focus()
      this.scanStarted = true
    },
    getProductDetails() {
      if (this.newItemBarcode) {
        this.flagVerifyingBarcode = true

        let config = {
          method: 'post',
          snackbar: false,
          params: {
            barcode: this.newItemBarcode,
          },
          overlay: true,
          url: this.endpoints.getProductDetails,
        };

        this.$refs.axios.submit(config)
      }
    },
    confirmTransaction() {
      this.flagPostingTransaction = true
      let allItems = this.existingItems.concat(this.newItems)

      let config = {
        method: 'post',
        snackbar: true,
        params: {
          items: allItems,
          mobile: this.mobileNumber,
          rvmId: this.currentRVM,
        },
        overlay: true,
        url: this.endpoints.storeTransaction,
      };

      this.dlgFinishScan = false
      this.$refs.axios.submit(config)
    },
    handleAxiosResponse(event) {
      if (event.data.result.code === 200) {
        let response = event.data.result.response

        switch (event.url) {
          case this.endpoints.getProductDetails:
            this.flagVerifyingBarcode = false

            if (response.id > 0)
              this.existingItems.push(response)
            else
              this.newItems.push(response)

            this.newItemBarcode = ""
            this.$refs.newItemBarcode.focus()
            break

          case this.endpoints.storeTransaction:
            this.existingItems = []
            this.newItems = []
            this.mobileNumber = ""
            this.flagPostingTransaction = false

            this.$refs.newItemBarcode.focus()
            this.$nextTick(() => this.$refs.newItemBarcode.focus())
            break

          default:
            break
        }
      }
    },
    deleteItem(items, item) {
      let index = items.findIndex(scannedItem => scannedItem.product_id === item.product_id)
      items.splice(index, 1)

      this.$refs.newItemBarcode.focus()
    }
  }
});
</script>
